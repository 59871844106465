/* You can add global styles to this file, and also import other style files */
.title-page {
  margin-top: 2px !important;
}

.right-element {
  float: right;
}

/* #region Inputs */

.input-100 {
  width: 100%;
}

.input-90 {
  width: 90%;
}

.input-75 {
  width: 75%;
}

.input-50 {
  width: 50%;
}
/* #endregion Inputs */

.right {
  text-align: right !important;
}

.datagrid-edit-icon {
  cursor: pointer;
  color: #0390cc;
}

.datagrid-edit-icon:hover {
  color: #015275;
}

.click-icon {
  color: #0390cc;
  cursor: pointer;
}

.click-icon:hover {
  color: #015275;
}

